<template>
  <div class="start-text">
    <div class="text-wrapper">
      <div>
        {{ data }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  name: 'StartText',
  props: {},
  data() {
    return {
      data: '传说,在布兰德星云中游荡着一艘巨型远古星舰,里面遗存大量稀有资源和古代科技.在同一世纪中有四方文明探测到了这艘飞船,并都企图占为己有,争夺之战就此展开...',
      renderText: '',
      timer: null,
      end: 1
    };
  },
  computed: {},
  created() {},
  mounted() {
    // this.start();
  },
  methods: {
    start() {
      this.timer = setTimeout(() => {
        if (this.end > this.data.length) {
          clearTimeout(this.timer);
          return;
        }
        this.renderText = this.data.slice(0, this.end++);
      }, 800);
    }
  }
});
</script>

<style scoped lang="scss">
.start-text {
  background: url('@/assets/images/start/bg.webp') no-repeat center/cover;
  .text-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 21.5vw;
    height: 18vw;
    color: #616161;
    text-align: center;
    line-height: 69px;
    letter-spacing: 2px;
    font-size: 18px;
    font-weight: 700;
    font-family: 'syht', sans-serif, serif;
  }
}
</style>
