<template>
  <div ref="startRef" class="start">
    <div class="bg space" data-depth="0.1">
      <img src="@/assets/images/start/space.webp" alt="space" />
    </div>
    <!-- window -->
    <div class="bg window" data-depth="0.1">
      <img src="@/assets/images/start/window.webp" alt="window" />
    </div>
    <!-- small person -->
    <div class="bg persons" data-depth="0.2">
      <img src="@/assets/images/start/persons.webp" alt="persons" />
    </div>
    <!-- lines -->
    <div class="bg persons">
      <img src="@/assets/images/start/lines.webp" alt="lines" />
    </div>
    <!-- girl -->
    <div class="bg girl" data-depth="0.4">
      <img src="@/assets/images/start/girl.webp" alt="girl" />
    </div>
    <!-- boy -->
    <div class="bg boy" data-depth="0.6">
      <img src="@/assets/images/start/boy.webp" alt="boy" />
    </div>
  </div>
</template>

<script>
import Parallax from 'parallax-js';

export default {
  name: 'StartComponent',
  props: {
    swiper: Object
  },
  data() {
    return {
      swiperInstance: null
    };
  },
  watch: {
    swiper(newSwiper) {
      if (newSwiper) {
        this.swiperInstance = newSwiper;
      }
    }
  },

  mounted() {
    new Parallax(this.$refs.startRef);
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.start {
  position: relative;
  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .space {
  }
  .window {
    img {
      position: absolute;
      left: -5%;
      width: 110%;
    }
  }
  .persons {
    img {
      object-fit: cover;
    }
  }
  .girl {
    img {
      position: absolute;
      left: 64vw;
      bottom: -3.5vw;
      width: auto;
      height: 37.7vw;
      object-fit: contain;
    }
  }
  .boy {
    img {
      position: absolute;
      left: 16.8vw;
      bottom: -1.3vw;
      width: auto;
      height: 49vw;
      object-fit: contain;
    }
  }
}
</style>
