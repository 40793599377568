<template>
  <transition name="messageTips">
    <div v-if="show" class="messageBox">
      <div class="message-icon">
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>
      </div>
      <span>NOTICE: "{{ message }}"</span>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'MessageTips',
  data() {
    return {
      show: false,
      message: ''
    };
  }
});
</script>

<style lang="scss" scoped>
@keyframes first {
  0% {
    height: 17%;
  }
  50% {
    height: 40%;
  }
  100% {
    height: 17%;
  }
}
@keyframes second {
  0% {
    height: 42%;
  }
  50% {
    height: 20%;
  }
  100% {
    height: 42%;
  }
}
@keyframes third {
  0% {
    height: 25%;
  }
  50% {
    height: 75%;
  }
  100% {
    height: 25%;
  }
}
@keyframes fourth {
  0% {
    height: 75%;
  }
  50% {
    height: 25%;
  }
  100% {
    height: 75%;
  }
}
@keyframes fifth {
  0% {
    height: 20%;
  }
  50% {
    height: 62%;
  }
  100% {
    height: 20%;
  }
}
@keyframes six {
  0% {
    height: 40%;
  }
  50% {
    height: 17%;
  }
  100% {
    height: 40%;
  }
}
.messageTips-enter-active,
.messageTips-leave-active {
  transition: 0.5s;
}

.messageTips-enter,
.messageTips-leave-to {
  opacity: 0;
}
.messageBox {
  position: fixed;
  left: 50%;
  top: 100px;
  display: flex;
  align-items: center;
  height: 60px;
  min-width: 416px;
  padding: 0 10px;
  color: #ffffff;
  transform: translateX(-50%);
  background: rgba(26, 27, 28, 0.8);
  border-radius: 50px;
  font-size: 20px;
  font-weight: 700;
  font-family: 'syht', sans-serif, serif;
  z-index: 999999;
  em {
    margin-right: 5rem;
    color: #ffffff;
  }
  .message-icon {
    display: flex;
    align-items: center;
    height: 40px;
    gap: 2px;
    .item {
      width: 3px;
      background: #ffffff;
      &:nth-of-type(1) {
        height: 17%;
        animation: first 1s linear infinite;
      }
      &:nth-of-type(2) {
        height: 42%;
        animation: second 1s infinite;
      }
      &:nth-of-type(3) {
        height: 75%;
        transform: translateY(-10%);
        animation: third 1s linear infinite;
      }
      &:nth-of-type(4) {
        height: 75%;
        animation: fourth 1s infinite;
      }
      &:nth-of-type(5) {
        height: 42%;
        animation: fifth 1s linear infinite;
      }
      &:nth-of-type(6) {
        height: 17%;
        animation: six 1s infinite;
      }
    }
  }
  span {
    font-size: bold;
    white-space: nowrap;
    margin-left: 20px;
  }
}
</style>
