var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"header"},[_c('div',{ref:"mainHeaderRef",staticClass:"main-header"},[_vm._m(0),_c('div',{staticClass:"permit",on:{"click":_vm.jumpQQ}},[_c('div',{ref:"triangleRef",staticClass:"triangle"}),_c('img',{attrs:{"src":require("@/assets/images/common/sword.webp"),"alt":"sword"}}),_c('span',[_vm._v("了解通行证")]),_c('div',{staticClass:"qq-group",on:{"click":(e) => {
              e.stopPropagation();
            }}},[_c('img',{attrs:{"src":require("@/assets/images/common/qq_group.webp"),"alt":"group"}})])])]),_c('div',{staticClass:"sub-header"},[_c('div',{staticClass:"lists"},_vm._l((_vm.header),function(item){return _c('div',{key:item.title,class:{
            item: true,
            active: item.pageNum === _vm.curPageNum && item.title !== '启程',
            'start-wrapper': item.title === '启程',
            left: item.type <= 2,
            right: item.type > 2
          },on:{"click":function($event){return _vm.handleHeaderClick(item)}}},[(item.title !== '启程')?_c('div',{staticClass:"title-wrapper"},[(item.pageNum !== _vm.curPageNum)?_c('div',{staticClass:"en-title"},[_vm._v(" "+_vm._s(item.enTitle)+" ")]):_vm._e(),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))])]):_c('div',{staticClass:"title-wrapper start",on:{"click":_vm.jumpQQ}},[_c('img',{attrs:{"src":require("@/assets/images/common/ju2.webp"),"alt":"","srcset":""}})])])}),0)])]),_c('div',{staticClass:"pages"},[_c('div',{staticClass:"swiper-container swiper-container-v swiper-no-swiping"},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide",attrs:{"data-hash":"start"}},[_c('Start',{staticClass:"page",attrs:{"swiper":_vm.swiper}})],1),_c('div',{staticClass:"swiper-slide",attrs:{"data-hash":"start-text"}},[_c('StartText',{staticClass:"page"})],1),_c('div',{staticClass:"swiper-slide",attrs:{"data-hash":"hero"}},[_c('Hero',{staticClass:"page"})],1),_c('div',{staticClass:"swiper-slide",attrs:{"data-hash":"team"}},[_c('Team',{staticClass:"page"})],1),_c('div',{staticClass:"swiper-slide",attrs:{"data-hash":"timeline"}},[_c('TimeLine',{staticClass:"page"})],1),_c('div',{staticClass:"swiper-slide",attrs:{"data-hash":"works"}},[_c('Works',{staticClass:"page",attrs:{"swiper":_vm.swiper,"mouse-wheel-control":_vm.mouseWheelControl}})],1),_c('div',{staticClass:"swiper-slide",attrs:{"data-hash":"qa"}},[_c('QA',{staticClass:"page"})],1)])])]),_c('div',{staticClass:"left-sidebar",on:{"wheel":_vm.handleWheel}},_vm._l((_vm.leftSidebar),function(item){return _c('div',{key:item.name,staticClass:"lf-item",on:{"click":function($event){return _vm.handleLeftSidebar(item)}}},[_c('div',{class:{
          'item-title': true,
          'small-title': item.isSmallTitle,
          active: item.pageNum === _vm.curPageNum
        }},[_vm._v(" "+_vm._s(item.name)+" "),(item.isNew)?_c('div',{staticClass:"new"},[_vm._v("NEW!")]):_vm._e()]),_c('transition',{attrs:{"name":"opacity"}},[(item.pageNum === _vm.curPageNum && !item.isSmallTitle)?_c('img',{staticClass:"arrow",attrs:{"src":require("@/assets/images/common/arrow.webp"),"alt":item.name}}):_vm._e()])],1)}),0),_c('div',{staticClass:"right-sidebar",on:{"wheel":_vm.handleWheel}},[_c('div',{staticClass:"rg-item oc",on:{"click":function($event){return _vm.handleRightSideBarClick('oc')}}},[_c('img',{attrs:{"src":require("@/assets/images/common/oc.webp"),"alt":"oc"}})]),_c('div',{staticClass:"rg-item info",on:{"click":function($event){return _vm.handleRightSideBarClick('info')}}},[_c('img',{attrs:{"src":require("@/assets/images/common/info.webp"),"alt":"info"}})]),_c('div',{staticClass:"rg-item plan"},[_c('div',{staticClass:"plan-text"},[_c('div',{staticClass:"cn"},[(!_vm.curPageNum)?_c('img',{attrs:{"src":require("@/assets/images/common/dhzzqh.webp"),"alt":"dhzzqh"}}):_c('img',{attrs:{"src":require("@/assets/images/common/xybj_font.webp"),"alt":"xybj"}})]),_c('div',{staticClass:"en"},[_vm._v("BLENDER X ANIMATION")])]),_vm._m(1)])]),_c('div',{staticClass:"footer",on:{"wheel":_vm.handleWheel}},[_c('div',{staticClass:"t-big"},[_c('div',{staticClass:"t-small"},[_c('div',{staticClass:"arrow",on:{"click":_vm.toTop}},[_c('img',{attrs:{"src":require("@/assets/images/footer/arrow.webp"),"alt":"arrow"}})])])]),_vm._m(2),_c('div',{staticClass:"links"},_vm._l((_vm.footer),function(item){return _c('div',{key:item.name,staticClass:"links__item",on:{"click":function($event){return _vm.handleLinkClick(item)}}},[_c('img',{attrs:{"src":item.icon,"alt":item.name}})])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"logo"},[_c('div',{staticClass:"icon"},[_c('img',{staticClass:"goblin-logo",attrs:{"src":require("@/assets/images/common/goblin_logo.webp"),"alt":"goblin-logo"}}),_c('img',{attrs:{"src":require("@/assets/images/common/xybj.webp"),"alt":"xybj"}})])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"btn-wrapper"},[_c('img',{staticClass:"btn",attrs:{"src":require("@/assets/images/common/play_btn.webp"),"alt":"btn"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"copyright"},[_c('a',{attrs:{"href":"https://beian.miit.gov.cn","target":"_blank"}},[_vm._v(" 好传动画版权所有【2011-2022】津ICP备12001033号-3")])])
}]

export { render, staticRenderFns }