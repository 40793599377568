<template>
  <div id="app">
    <!-- header -->
    <div class="header">
      <div ref="mainHeaderRef" class="main-header">
        <div class="logo">
          <div class="icon">
            <img
              src="@/assets/images/common/goblin_logo.webp"
              class="goblin-logo"
              alt="goblin-logo"
            />
            <img src="@/assets/images/common/xybj.webp" alt="xybj" />
          </div>
        </div>
        <!-- permit -->
        <div class="permit" @click="jumpQQ">
          <div ref="triangleRef" class="triangle"></div>
          <img src="@/assets/images/common/sword.webp" alt="sword" />
          <span>了解通行证</span>

          <div
            class="qq-group"
            @click="
              (e) => {
                e.stopPropagation();
              }
            "
          >
            <img src="@/assets/images/common/qq_group.webp" alt="group" />
          </div>
        </div>
      </div>

      <!-- sub-header -->
      <div class="sub-header">
        <div class="lists">
          <div
            :class="{
              item: true,
              active: item.pageNum === curPageNum && item.title !== '启程',
              'start-wrapper': item.title === '启程',
              left: item.type <= 2,
              right: item.type > 2
            }"
            v-for="item in header"
            :key="item.title"
            @click="handleHeaderClick(item)"
          >
            <div v-if="item.title !== '启程'" class="title-wrapper">
              <div v-if="item.pageNum !== curPageNum" class="en-title">
                {{ item.enTitle }}
              </div>
              <div class="title">{{ item.title }}</div>
            </div>
            <div v-else class="title-wrapper start" @click="jumpQQ">
              <img src="@/assets/images/common/ju2.webp" alt="" srcset="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pages">
      <div class="swiper-container swiper-container-v swiper-no-swiping">
        <div class="swiper-wrapper">
          <div class="swiper-slide" data-hash="start">
            <Start :swiper="swiper" class="page"></Start>
          </div>
          <div class="swiper-slide" data-hash="start-text">
            <StartText class="page"></StartText>
          </div>
          <div class="swiper-slide" data-hash="hero">
            <Hero class="page"></Hero>
          </div>
          <div class="swiper-slide" data-hash="team">
            <Team class="page"></Team>
          </div>
          <div class="swiper-slide" data-hash="timeline">
            <TimeLine class="page"></TimeLine>
          </div>
          <div class="swiper-slide" data-hash="works">
            <Works
              :swiper="swiper"
              class="page"
              :mouse-wheel-control="mouseWheelControl"
            ></Works>
          </div>
          <div class="swiper-slide" data-hash="qa">
            <QA class="page"></QA>
          </div>
        </div>
        <!-- <div class="swiper-pagination"></div> -->
      </div>
    </div>
    <!-- left sidebar -->
    <div class="left-sidebar" @wheel="handleWheel">
      <div
        class="lf-item"
        v-for="item in leftSidebar"
        :key="item.name"
        @click="handleLeftSidebar(item)"
      >
        <div
          :class="{
            'item-title': true,
            'small-title': item.isSmallTitle,
            active: item.pageNum === curPageNum
          }"
        >
          {{ item.name }}
          <div v-if="item.isNew" class="new">NEW!</div>
        </div>
        <transition name="opacity">
          <img
            v-if="item.pageNum === curPageNum && !item.isSmallTitle"
            src="@/assets/images/common/arrow.webp"
            class="arrow"
            :alt="item.name"
          />
        </transition>
      </div>
    </div>
    <!-- right sidebar -->
    <div class="right-sidebar" @wheel="handleWheel">
      <div class="rg-item oc" @click="handleRightSideBarClick('oc')">
        <img src="@/assets/images/common/oc.webp" class="" alt="oc" />
      </div>
      <div class="rg-item info" @click="handleRightSideBarClick('info')">
        <img src="@/assets/images/common/info.webp" class="" alt="info" />
      </div>
      <div class="rg-item plan">
        <div class="plan-text">
          <div class="cn">
            <img
              v-if="!curPageNum"
              src="@/assets/images/common/dhzzqh.webp"
              alt="dhzzqh"
            />
            <img
              v-else
              src="@/assets/images/common/xybj_font.webp"
              alt="xybj"
            />
          </div>
          <div class="en">BLENDER X ANIMATION</div>
        </div>
        <div class="btn-wrapper">
          <img
            src="@/assets/images/common/play_btn.webp"
            class="btn"
            alt="btn"
          />
        </div>
      </div>
    </div>
    <!-- footer -->
    <div class="footer" @wheel="handleWheel">
      <div class="t-big">
        <div class="t-small">
          <div class="arrow" @click="toTop">
            <img src="@/assets/images/footer/arrow.webp" alt="arrow" />
          </div>
        </div>
      </div>
      <div class="copyright">
        <a href="https://beian.miit.gov.cn" target="_blank">
          好传动画版权所有【2011-2022】津ICP备12001033号-3</a
        >
      </div>
      <div class="links">
        <div
          v-for="item in footer"
          :key="item.name"
          class="links__item"
          @click="handleLinkClick(item)"
        >
          <img :src="item.icon" :alt="item.name" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Swiper from 'swiper';
import Start from '@/components/Start.vue';
import StartText from '@/components/StartText.vue';
import Hero from '@/components/Hero.vue';
import Team from '@/components/Team.vue';
import TimeLine from '@/components/TimeLine.vue';
import Works from '@/components/Works.vue';
import QA from '@/components/QA.vue';

import { throttle } from '@/utils/utils.js';

const PAGE_MAP = new Map([
  ['首页', [0, 0]],
  ['启程', [0, 0]],
  ['启程text', [0, 1]],
  ['OC英雄', [1, 2]],
  ['阵营', [2, 3]],
  ['时间线', [3, 4]],
  ['往期作品', [4, 5]],
  ['哥布林', [5, null]],
  ['常见问题', [6, 6]]
]);

export default Vue.extend({
  name: 'App',
  components: { Start, StartText, Hero, Team, TimeLine, Works, QA },
  data() {
    return {
      curPageNum: 0,
      activeName: '首页',
      swiper: null,
      header: [
        {
          title: '首页',
          enTitle: 'INDEX',
          active: true,
          type: PAGE_MAP.get('首页')[0],
          pageNum: PAGE_MAP.get('首页')[1]
        },
        {
          title: 'OC英雄',
          enTitle: 'CHAR',
          active: false,
          type: PAGE_MAP.get('OC英雄')[0],
          pageNum: PAGE_MAP.get('OC英雄')[1]
        },
        {
          title: '阵营',
          enTitle: 'CAMP',
          active: false,
          type: PAGE_MAP.get('阵营')[0],
          pageNum: PAGE_MAP.get('阵营')[1]
        },
        {
          title: '启程',
          enTitle: 'START',
          active: false,
          disableClick: true,
          type: PAGE_MAP.get('启程')[0],
          pageNum: PAGE_MAP.get('启程')[1]
        },
        {
          title: '时间线',
          enTitle: 'TIMELINE',
          active: false,
          type: PAGE_MAP.get('时间线')[0],
          pageNum: PAGE_MAP.get('时间线')[1]
        },
        {
          title: '往期作品',
          enTitle: 'PREVIOUS WORKS',
          active: false,
          type: PAGE_MAP.get('往期作品')[0],
          pageNum: PAGE_MAP.get('往期作品')[1]
        },
        {
          title: '常见问题',
          enTitle: 'QUESTION',
          active: false,
          type: PAGE_MAP.get('常见问题')[0],
          pageNum: PAGE_MAP.get('常见问题')[1]
        }
      ],
      leftSidebar: [
        {
          name: '启程',
          type: PAGE_MAP.get('启程')[0],
          pageNum: PAGE_MAP.get('启程')[1]
        },
        {
          name: 'OC英雄',
          type: PAGE_MAP.get('OC英雄')[0],
          pageNum: PAGE_MAP.get('OC英雄')[1]
        },
        {
          name: '阵营',
          type: PAGE_MAP.get('阵营')[0],
          pageNum: PAGE_MAP.get('阵营')[1]
        },
        {
          name: '时间线',
          isNew: true,
          type: PAGE_MAP.get('时间线')[0],
          pageNum: PAGE_MAP.get('时间线')[1]
        },
        {
          name: '往期作品',
          isSmallTitle: true,
          type: PAGE_MAP.get('往期作品')[0],
          pageNum: PAGE_MAP.get('往期作品')[1]
        },
        {
          name: '常见问题',
          isSmallTitle: true,
          type: PAGE_MAP.get('常见问题')[0],
          pageNum: PAGE_MAP.get('常见问题')[1]
        }
      ],
      footer: [
        {
          name: 'qq',
          href: 'http://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=b6oO3QExAykj97HGA0TBkaJrobzSreNf&authKey=YunqYNLvFg%2FckRj4q5kIl3N%2BVu3jdPbJ0%2BjJVopDMO%2Bl8m6inyu7QW4JvBfUSfI7&noverify=0&group_code=961978227',
          icon: require('@/assets/images/footer/qq.webp')
        },
        {
          name: 'wechat',
          href: 'https://mp.weixin.qq.com/s/JbkHzNnTQMnnhXNsPsJwAg',
          icon: require('@/assets/images/footer/wechat.webp')
        },
        {
          name: 'sina',
          href: 'https://weibo.com/7434717290',
          icon: require('@/assets/images/footer/sina.webp')
        },
        {
          name: 'bili',
          href: 'https://space.bilibili.com/2722124',
          icon: require('@/assets/images/footer/bili.webp')
        }
      ]
    };
  },
  created() {},
  mounted() {
    const swiper = new Swiper('.swiper-container-v', {
      direction: 'vertical',
      spaceBetween: 0,
      mousewheel: true,
      hashNavigation: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
    });

    this.swiper = swiper;
    swiper.on('slideChangeTransitionEnd', () => {
      this.updatePageNum();
    });
    this.updatePageNum();
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },
  methods: {
    toTop() {
      this.swiper.slideTo(0, 1000);
    },
    updatePageNum() {
      const activeIndex = this.swiper.activeIndex;
      this.curPageNum = activeIndex;
    },
    handleRightSideBarClick(type) {
      if (type === 'oc') {
        this.$message('你可以创建自己的角色');
        return;
      }
      this.$message('点击右上角 进入官方QQ群了解详情吧');
    },
    jumpQQ() {
      window.open(
        'http://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=b6oO3QExAykj97HGA0TBkaJrobzSreNf&authKey=YunqYNLvFg%2FckRj4q5kIl3N%2BVu3jdPbJ0%2BjJVopDMO%2Bl8m6inyu7QW4JvBfUSfI7&noverify=0&group_code=961978227',
        '__blank'
      );
    },
    handleLinkClick(item) {
      window.open(item.href, '__blank');
    },
    handleLeftSidebar(item) {
      this.swiper.slideTo(item.pageNum, 1000);
      this.curPageNum = item.pageNum;
      if (item.type === PAGE_MAP.get('常见问题')) {
        return;
      }
      this.curPageNum = item.pageNum;
    },
    handleHeaderClick(item) {
      if (item.disableClick) {
        return;
      }
      this.swiper.slideTo(item.pageNum, 1000);
      this.curPageNum = item.pageNum;
    },
    onResize() {
      const triangleRef = this.$refs.triangleRef;
      const mainHeaderRef = this.$refs.mainHeaderRef;
      if (triangleRef && mainHeaderRef) {
        triangleRef.style.borderWidth = `${mainHeaderRef.offsetHeight / 2}px`;
      }
    },
    handleWheel(event) {
      this.throttleHandleWheel({ event, swiper: this.swiper });
    },
    throttleHandleWheel: throttle(({ event, swiper }) => {
      const delta = event.deltaY;

      if (delta > 0) {
        // down
        swiper.slideNext();
      } else {
        // up
        swiper.slidePrev();
      }
    }, 700),
    mouseWheelControl() {
      return {
        disable: this.swiper.mousewheel.disable,
        enable: this.swiper.mousewheel.enable
      };
    }
  }
});
</script>

<style scoped lang="scss">
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.opacity-enter-active {
  transition: 0.25s;
  animation: show 0.25s linear forwards;
}

.opacity-leave-to {
  animation: show 0.25s reverse;
}
#app {
  position: relative;
  height: 100%;
  color: #fff;
  // header
  .header {
    position: fixed;
    top: 0;
    left: 0;
    height: 48px;
    width: 100%;
    min-width: 1300px;
    background: #000000;
    z-index: 9999;
    .main-header {
      display: flex;
      justify-content: space-between;
      height: 100%;
      .logo {
        cursor: pointer;
        margin: 10px 15px;
        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 12px;
          .goblin-logo {
            width: 33px;
            height: 33px;
          }
        }
      }
      .permit {
        cursor: pointer;
        position: relative;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 216px;
        height: 100%;
        background: #ffc300;
        font-size: 20px;
        font-weight: 700;
        font-family: 'syht', sans-serif, serif;
        &:hover .qq-group {
          display: block;
          opacity: 1;
        }
        .triangle {
          position: absolute;
          left: 0;
          top: 0;
          width: 0;
          height: 0;
          border-color: #000 transparent transparent #000;
          border-style: solid;
        }
        & > img {
          width: 22px;
          height: 22px;
          margin-left: 30px;
        }
        .qq-group {
          display: none;
          opacity: 0;
          position: absolute;
          top: 70px;
          right: 50px;
          border-radius: 30px;
          img {
            width: 179px;
            height: 269px;
          }
        }
      }
    }

    // sub-header
    .sub-header {
      position: absolute;
      left: 50%;
      top: 0.6vw;
      width: 1230px;
      // min-width: 1230px;
      padding-top: 10px;
      transform: translateX(-50%);
      background: url('@/assets/images/common/sub_header_bg.webp') no-repeat
        center/contain;
      font-size: 20px;
      font-weight: 700;
      font-family: 'syht', sans-serif, serif;
      .lists {
        display: flex;
        justify-content: center;
        & > .item {
          cursor: pointer;
          width: 8.3vw;
          min-width: 159px;
          text-align: center;
          &:hover:not(.start-wrapper) .title-wrapper .en-title {
            display: none;
          }
          &.active.left:not(.start-wrapper),
          &:hover:not(.start-wrapper):not(.right) {
            background: url('@/assets/images/common/slider_bg_left.webp')
              no-repeat center/contain;
          }

          &.active.right:not(.start-wrapper),
          &:hover:not(.start-wrapper):not(.left) {
            background: url('@/assets/images/common/slider_bg_right.webp')
              no-repeat center/contain;
          }

          &:not(.start-wrapper) {
            position: relative;
            top: -10px;
          }
          &.active .title-wrapper .en-title {
            color: #fff;
          }
          &:hover .title-wrapper .en-title {
            color: #fff;
          }
          &.start-wrapper {
            margin: 0 30px;
          }
          .title-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 100%;
            white-space: nowrap;
            .en-title {
              color: #808080;
              font-size: 12px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
  // left-sidebar
  .left-sidebar {
    position: absolute;
    left: 5.7vw;
    top: 11.4vw;
    .lf-item {
      cursor: pointer;
      display: flex;
      gap: 10px;
      align-items: center;
      width: 13vw;
      font-size: 55px;
      font-weight: 700;
      font-family: 'alph', sans-serif, serif;
      transition: all 0.2s linear;
      &:hover:not(.active) {
        transform: scale(1.4) translateX(-0.8vw);
      }
      &:hover .item-title.small-title {
        color: #fff;
      }
      .item-title {
        position: relative;
        height: 76px;
        margin-bottom: 5px;
        white-space: nowrap;
        transition: all 0.3s linear;
        &:hover.small-title {
          color: #fff;
        }
        &.small-title {
          height: 33px;
          color: rgba(255, 255, 255, 0.61);
          font-size: 24px;
          font-weight: 700;
          &.active {
            color: #fff;
          }
        }
        & .new {
          position: absolute;
          top: -22px;
          right: -30px;
          padding: 10px;
          color: #000;
          font-size: 16px;
          font-weight: 700;
          font-family: 'alph', sans-serif, serif;
          background: url('@/assets/images/common/new_bg.webp') no-repeat
            center/contain;
        }
      }
      & > img {
        height: 2.1vw;
      }
    }
  }
  // right-sidebar
  .right-sidebar {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    bottom: 4vw;
    text-align: right;
    & * {
      transition: all 0.2s ease;
    }
    .rg-item {
      cursor: pointer;
      margin-bottom: 2.6vw;
      &.oc,
      &.info {
        align-self: self-end;
        width: fit-content;
        margin-right: 3.1vw;
      }
      &.plan {
        position: relative;
        display: flex;
        justify-content: center;
        height: auto;
        background: rgba(0, 0, 0, 0.22);
        border: 1px solid rgba(255, 255, 255, 0.22);
        &::before {
          content: '';
          position: absolute;
          left: 0;
          width: 79px;
          height: 4px;
          background: #39e5cf;
        }
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 218px;
          height: 18px;
          background: #39e5cf;
        }
        .plan-text {
          margin: 0.5vw 5.2vw 2vw 0.9vw;
          text-align: left;
          .cn {
            & > img {
              height: 4.8vw;
            }
          }
          .en {
            font-size: 24px;
            font-weight: 900;
            font-family: 'alph', sans-serif, serif;
          }
        }
        .btn-wrapper {
          display: flex;
          align-items: center;
          width: 5.2vw;
          margin-right: 40px;
          & > img {
            width: 100%;
          }
        }
      }
    }
  }
  // footer
  .footer {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 3.8vw;
    background: #000000;
    z-index: 9999;
    .t-big {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -6.7vw;
      left: 50%;
      transform: translateX(-50%);
      width: 64vw;
      height: 3.1vw;
      background: #000000;
      text-align: center;
      background: transparent;
      border-top: 3.1vw solid transparent;
      border-bottom: 3.1vw solid #000000;
      border-left: 3.1vw solid transparent;
      border-right: 3.1vw solid transparent;
      .t-small {
        display: flex;
        justify-content: center;
        position: relative;
        top: -0.5vw;
        width: 16.5vw;
        background: transparent;
        border-top: 3.6vw solid transparent;
        border-bottom: 3.6vw solid #000000;
        border-left: 3.6vw solid transparent;
        border-right: 3.6vw solid transparent;
        .arrow {
          cursor: pointer;
          position: relative;
          top: 40px;
          width: 1.7vw;
          height: 1.7vw;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .copyright {
      z-index: 2;
      a {
        text-decoration: none;
        color: rgba(255, 255, 255, 0.47);
        font-size: 12px;
        margin-left: 3vw;
      }
    }
    .links {
      display: flex;
      gap: 1.3vw;
      height: 1vw;
      margin-right: 3vw;
      z-index: 2;
      img {
        cursor: pointer;
        height: 100%;
      }
    }
  }
  // pages
  .pages {
    height: 100%;
    .swiper-container {
      width: 100%;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
      .swiper-wrapper {
        height: 100%;
        .swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          // text-align: center;
          font-size: 18px;
          background: #fff;
        }
      }
    }
  }
}

@media screen and (max-width: 1700px) {
  #app {
    .header .sub-header {
      transform: translateX(-50%) scale(0.7);
    }
    .right-sidebar {
      .rg-item.plan {
        transform: scale(0.5) translateX(50%);
      }
    }
    .footer {
      height: 45px;
      .t-big {
        top: -4.7vw;
        width: 40vw;
        border-top: 1vw solid transparent;
        border-bottom: 1vw solid #000000;
        border-left: 1vw solid transparent;
        border-right: 1vw solid transparent;
        .t-small {
          top: 0;
          border-top: 12px solid transparent;
          border-bottom: 12px solid #000000;
          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
        }
      }
    }
  }
}
</style>
