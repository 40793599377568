<template>
  <div class="works">
    <div class="works-wrapper">
      <div class="title-wrapper">
        <div class="title">往期作品</div>
        <div class="title-en">PREVIOUS WORKS</div>
      </div>
      <div
        ref="worksListRef"
        class="swiper-container swiper-container-h works-list"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
        @wheel="handleWheel"
      >
        <div ref="swiperWrapperRef" class="swiper-wrapper">
          <div
            class="swiper-slide works-item"
            v-for="item in works"
            :key="item.title"
          >
            <div class="poster" @click="handleClick(item)">
              <img :src="item.poster" :alt="item.title" />
            </div>
            <div class="category-wrapper">
              <div class="category">{{ item.category }}</div>
              <div class="tag">{{ item.tag }}</div>
            </div>
            <div class="title-wrapper">
              <div class="title">{{ item.title }}</div>
              <div class="desc">{{ item.desc }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { debounce } from '@/utils/utils.js';

export default Vue.extend({
  name: 'WorksComponent',
  props: {
    mouseWheelControl: {
      type: Function
    },
    swiper: {
      type: Object
    }
  },
  data() {
    return {
      works: [
        {
          title: '《蝶-Mimesis》',
          desc: '人工智能与人类的反思',
          category: 'Blender',
          tag: '3D动画',
          poster: require('@/assets/images/works/d.webp'),
          href: 'https://www.bilibili.com/video/BV17j41117kH/'
        },
        {
          title: '《档案》',
          desc: '图书馆管理员的奇遇',
          category: 'TVpaint',
          tag: '2D动画',
          poster: require('@/assets/images/works/da.webp'),
          href: 'https://www.bilibili.com/video/BV1q54y1g7en/'
        },
        {
          title: '《困兽》',
          desc: '囚徒之间的生死决斗',
          category: 'TVpaint',
          tag: '2D动画',
          poster: require('@/assets/images/works/ks.webp'),
          href: 'https://www.bilibili.com/video/BV1Kr4y137ED/'
        },
        {
          title: '《怕黑》',
          desc: '深夜的脑洞畅想',
          category: 'TVpaint',
          tag: '2D动画',
          poster: require('@/assets/images/works/ph.webp'),
          href: 'https://www.bilibili.com/video/BV14U4y1k7ZE/'
        },
        {
          title: '《小小英雄的下午》',
          desc: '童年关于英雄的梦',
          category: 'TVpaint',
          tag: '2D动画',
          poster: require('@/assets/images/works/xxyxdxw.webp'),
          href: 'https://www.bilibili.com/video/BV1Na411D7in/'
        },
        {
          title: '《杀死阿姆》',
          desc: '赏金猎人英雄救美',
          category: 'TVpaint',
          tag: '2D动画',
          poster: require('@/assets/images/works/ssam.webp'),
          href: 'https://www.bilibili.com/video/BV1AA4y1d7Yi/'
        },
        {
          title: '《魔术师》',
          desc: '身体有缺陷的魔术师与马戏团的故事',
          category: 'TVpaint',
          tag: '2D动画',
          poster: require('@/assets/images/works/mss.webp'),
          href: 'https://www.bilibili.com/video/BV1EF411q7NP/'
        },
        {
          title: '《绝交》',
          desc: '我和同学绝交之后的趣事',
          category: 'TVpaint',
          tag: '2D动画',
          poster: require('@/assets/images/works/jj.webp'),
          href: 'https://www.bilibili.com/video/BV163411t7ft/'
        },
        {
          title: '《早餐》',
          desc: '为了做早餐发生的一系列糗事',
          category: 'TVpaint',
          tag: '2D动画',
          poster: require('@/assets/images/works/zc.webp'),
          href: 'https://www.bilibili.com/video/BV1qS4y197Jf/'
        },
        {
          title: '《绿城》',
          desc: '无止境的欲望会发生什么呢',
          category: 'TVpaint',
          tag: '2D动画',
          poster: require('@/assets/images/works/lc.webp'),
          href: 'https://www.bilibili.com/video/BV1Mq4y1f7J3/'
        },
        {
          title: '《结》',
          desc: '狼孩和猎人的孩子的故事',
          category: 'TVpaint',
          tag: '2D动画',
          poster: require('@/assets/images/works/j.webp'),
          href: 'https://www.bilibili.com/video/BV1RU4y1P7h6/'
        }
      ]
    };
  },
  computed: {},
  created() {},
  mounted() {
    // const swiper = new Swiper('.swiper-container-h', {
    //   direction: 'horizontal',
    //   mousewheel: true,
    //   freeMode: true
    // });
  },
  methods: {
    debounceHandleWheel: debounce(
      ({ worksListRef, swiperWrapperRef, swiper }) => {
        const scrollTop = worksListRef.scrollTop;
        const scrollHeight = swiperWrapperRef.scrollHeight;
        const clientHeight = worksListRef.clientHeight;

        if (scrollTop + clientHeight >= scrollHeight) {
          swiper.slideNext(1000);
        }
        if (!scrollTop) {
          swiper.slidePrev(1000);
        }
      },
      400
    ),
    handleWheel() {
      if (this.swiper.animating) {
        return;
      }
      const worksListRef = this.$refs.worksListRef;
      const swiperWrapperRef = this.$refs.swiperWrapperRef;

      this.debounceHandleWheel({
        worksListRef,
        swiperWrapperRef,
        swiper: this.swiper
      });
    },
    handleClick(item) {
      window.open(item.href, '__blank');
    },
    handleMouseEnter() {
      this.mouseWheelControl().disable();
    },
    handleMouseLeave() {
      this.mouseWheelControl().enable();
    }
  }
});
</script>

<style scoped lang="scss">
.works {
  background: url('@/assets/images/common/bg.webp') no-repeat center/cover;

  .works-wrapper {
    position: absolute;
    left: 50%;
    top: 42%;
    transform: translate(-50%, -50%);
    font-family: 'alph', sans-serif, serif;
    .title-wrapper {
      margin-bottom: 2.3vw;
      .title {
        font-size: 72px;
        font-weight: 700;
      }
      .title-en {
        color: rgba(255, 255, 255, 0.47);
        font-size: 14px;
        font-weight: 500;
      }
    }

    .works-list {
      width: 50vw;
      height: 40vh;
      padding: 20px;
      font-family: 'alph', sans-serif, serif;
      overflow: hidden scroll;
      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.9);
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      .swiper-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0.7vw;
        .works-item {
          .poster {
            cursor: pointer;
            // width: 17.5vw;
            height: 10vw;
            margin-bottom: 15px;
            border-radius: 30px 0px 30px 7px;
            overflow: hidden;
            & > img {
              width: 100%;
              transition: all 0.2s linear;
              &:hover {
                transform: scale(1.1);
              }
            }
          }
          .category-wrapper {
            display: flex;
            align-items: center;
            gap: 10px;
            .category {
              font-size: 20px;
              font-weight: 700;
            }
            .tag {
              height: 23px;
              padding: 0px 10px;
              color: #000;
              font-size: 16px;
              font-weight: 700;
              background: url('@/assets/images/works/tag_bg.webp') no-repeat
                center/cover;
            }
          }
          .title-wrapper {
            .title {
              font-size: 36px;
              font-weight: 700;
            }
            .desc {
              font-size: 14px;
              font-weight: 500;
              color: rgba(255, 255, 255, 0.47);
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1700px) {
  .works {
    .works-wrapper {
      top: 50%;
      .works-list {
        .swiper-wrapper {
          .works-item {
            .poster {
              height: 8vw;
            }
            .category-wrapper {
              display: flex;
              align-items: center;
              gap: 10px;
              .category {
                font-size: 16px;
              }
              .tag {
                transform: scale(0.8);
              }
            }
            .title-wrapper {
              .title {
                font-size: 22px;
              }
              .desc {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
