<template>
  <div class="team">
    <div class="team-wrapper">
      <div class="title-wrapper">
        <div class="title">阵营信息</div>
        <div class="title-en">CAMP INFORMATION</div>
      </div>
      <div class="team-list">
        <div class="team-item" v-for="item in data" :key="item.title">
          <div class="item-desc">{{ item.desc }}</div>
          <div class="item-title" :style="`background: ${item.color}`">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  name: 'TeamComponent',
  props: {},
  data() {
    return {
      data: [
        {
          color: '#2A82E4',
          title: '人形态文明',
          desc: '人类与人类亚种（亚人）的文明势力，不同星区的文化和发展水平各不相同，风格各异'
        },
        {
          color: '#CA2E2E',
          title: '异形态文明',
          desc: '与人类形态不同的多种外星种族，其形态和特点五花八门。科技水平与文化也各不相同'
        },
        {
          color: '#DEA705',
          title: '机械文明',
          desc: '完全由无机体构成的文明，包括具有不同程度自我意识的机械生命体'
        },
        {
          color: '#1F0093',
          title: '高概念文明',
          desc: '以意识、能量或概念的形式存在，已经脱离了常规肉体束缚，不可名状的生命形态'
        }
      ]
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {}
});
</script>

<style scoped lang="scss">
.team {
  background: url('@/assets/images/common/bg.webp') no-repeat center/cover;
  .team-wrapper {
    position: absolute;
    left: 50%;
    top: 42%;
    transform: translate(-50%, -50%);
    font-family: 'alph', sans-serif, serif;
    .title-wrapper {
      margin-bottom: 2.3vw;
      .title {
        font-size: 72px;
        font-weight: 700;
      }
      .title-en {
        color: rgba(255, 255, 255, 0.47);
        font-size: 14px;
        font-weight: 500;
      }
    }

    .team-list {
      display: flex;
      gap: 2.4vw;

      .team-item {
        position: relative;
        width: 10.4vw;
        height: 16.3vw;
        padding: 1.5vw 1vw 0 1vw;
        color: rgba(255, 255, 255, 0.47);
        border-radius: 20px;
        background: rgba(255, 255, 255, 0.13);
        .item-desc {
          font-size: 14px;
          font-weight: 500;
        }
        .item-title {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2.6vw;
          color: #fff;
          font-size: 24px;
          font-weight: 700;
        }
      }
    }
  }
}
@media screen and (max-width: 1700px) {
  .team {
    .team-wrapper {
      top: 47%;
    }
  }
}
</style>
