<template>
  <div class="qa">
    <div class="qa-wrapper">
      <div class="title-wrapper">
        <div class="title">常见问题</div>
        <div class="title-en">FREQUENTLY ASKED QUESTION</div>
      </div>
      <div class="qa-list">
        <div class="qa-item" v-for="item in qa" :key="item.q">
          <div class="q">{{ item.q }}</div>
          <div class="a">
            <span v-html="item.a"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  name: 'QA',
  props: {},
  data() {
    return {
      qa: [
        {
          q: '1：没有基础可以参与吗?',
          a: '可以的。我们会从最基础的Blender软件教学开始，带你从软件小白一步步进阶为专业人士。学习日常我们还会整理相关的基础练习，从根源帮助你解决基础差等相关问题。'
        },
        {
          q: '2：普通票和VIP票有什么区别',
          a: '<strong style="color: #fff;">普通票</strong>内含基础的新手包（软件基础操作相关学视频及软件安装包等），及每周两次的答疑讲座，每周一次的大咖专业讲座等。<br /><strong style="color: #fff">VIP票</strong>在普通票的基础上增加专属的助教团及一对一连线答疑等专属内容。'
        },
        {
          q: '3：起点阶段和进阶阶段的区别有什么？',
          a: '<strong style="color: #fff;">起点阶段</strong>主要针对软件教学及OC的设计及建模等相关内容，旨在 <strong style="color: #fff;">创建个人的原创角色</strong>。<br /> <strong style="color: #fff;">进阶阶段</strong>主要针对给创建好的OC制作专属的故事，内容包含 <strong style="color: #fff;">分镜、角色绑定及动画制作</strong>等。'
        },
        {
          q: '4：如何报名及参与？',
          a: '可以通过扫描关注我们的官网微博及活动专属QQ咨询群来找到我们。加群后管理员会和您联系并告知需要准备的相关资料等。'
        },
        {
          q: '5：关于退款如何安排？',
          a: '支持。<strong style="color: #fff;">7天无理由退款</strong>。'
        }
      ]
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {}
});
</script>

<style scoped lang="scss">
.qa {
  background: url('@/assets/images/common/bg.webp') no-repeat center/cover;
  .qa-wrapper {
    position: absolute;
    left: 50%;
    top: 42%;
    transform: translate(-50%, -50%);
    font-family: 'alph', sans-serif, serif;
    .title-wrapper {
      margin-bottom: 2.3vw;
      .title {
        font-size: 72px;
        font-weight: 700;
      }
      .title-en {
        color: rgba(255, 255, 255, 0.47);
        font-size: 14px;
        font-weight: 500;
      }
    }

    .qa-list {
      font-family: 'alph', sans-serif, serif;
      .qa-item {
        margin-bottom: 12px;
        & > * {
          line-height: 28px;
        }
        .q {
          font-size: 20px;
          font-weight: 700;
        }
        .a {
          color: rgba(255, 255, 255, 0.47);
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
}
@media screen and (max-width: 1700px) {
  .qa {
    .qa-wrapper {
      top: 50%;
      .title-wrapper {
        margin-bottom: 20px;
        .title {
          font-size: 72px;
        }
      }
      .qa-list {
        .qa-item {
          margin-bottom: 4px;
          & > * {
            line-height: 26px;
          }
          .q {
            font-size: 18px;
          }
          .a {
            font-size: 13px;
          }
        }
      }
    }
  }
}
</style>
