import Message from '@/components/Message';

let timer;

export default {
  install: function (Vue) {
    Vue.prototype.$message = (message) => {
      if (!timer) {
        const instance = new Message({
          data: {
            message
          }
        });
        const modal = instance.$mount();
        document.body.appendChild(modal.$el);
        modal.show = true;
        timer = setTimeout(() => {
          modal.$destroy();
          modal.$el.parentNode.removeChild(modal.$el);
          timer = null;
        }, 3000);
      }
    };
  }
};
