import Vue from 'vue';
import App from './App.vue';
import '@/assets/scss/global.scss';
import '@/assets/scss/font.scss';

import Plugins from './plugin';

Vue.config.productionTip = false;
Vue.use(Plugins);

new Vue({
  render: (h) => h(App)
}).$mount('#app');
