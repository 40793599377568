<template>
  <div class="hero">
    <div class="person">
      <img src="@/assets/images/hero/person.webp" alt="person" />
    </div>
    <div class="text-wrapper">
      <div class="human">HUMAN</div>
      <div class="oc">
        <div class="oc-title">
          <div class="title-bg"></div>
          <img src="@/assets/images/hero/oc_title.webp" alt="oc" />
        </div>
        <div class="oc-desc">
          借助Blender制作你自己的角色，并让他/她加入宇宙探索的故事从零开始，点亮oc人生的火花。
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  name: 'HeroComponent',
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {}
});
</script>

<style scoped lang="scss">
.hero {
  background: url('@/assets/images/hero/bg.webp') no-repeat center/cover;
  .person {
    position: absolute;
    left: 26.4vw;
    top: 10.4vw;
    width: 24.6vw;
    height: 34.8vw;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .text-wrapper {
    position: absolute;
    bottom: 21.7vw;
    right: 18.2vw;
    .human {
        text-align: center;
      color: rgba(255, 255, 255, 0.3);
      font-size: 36px;
      font-weight: 700;
      font-family: 'alph', sans-serif, serif;
    }
    .oc {
      .oc-title {
        display: flex;
        align-items: center;
        position: relative;
        width: 13.3vw;
        height: 4.8vw;

        .title-bg {
          flex-shrink: 0;
          width: 556px;
          height: 18px;
          background: linear-gradient(90deg, #a5d63f 0%, #a5d63f 100%);
          filter: blur(40px);
          z-index: 1;
        }
        img {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 13.3vw;
          height: 4.8vw;
          z-index: 2;
        }
      }
      .oc-desc {
        width: 23.5vw;
        font-size: 16px;
        font-weight: 500;
        font-family: 'syht', sans-serif, serif;
      }
    }
  }
}
</style>
