<template>
  <div class="timeline">
    <div class="timeline-wrapper">
      <div class="title-wrapper">
        <div class="title">时间线</div>
        <div class="title-en">TIMELINE</div>
      </div>
      <div class="timeline-list">
        <div class="line">
          <div
            class="timeline-item"
            v-for="(item, index) in timelines"
            :key="item.title"
          >
            <div
              :class="{
                'content-wrapper': true,
                up: !(index % 2),
                down: index % 2
              }"
            >
              <div class="title">{{ item.title }}</div>
              <div class="desc">{{ item.desc }}</div>
            </div>
            <div :class="{ date: true, up: index % 2, down: !(index % 2) }">
              {{ item.date }}
            </div>
            <div class="dot"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  name: 'TimeLine',
  props: {},
  data() {
    return {
      timelines: [
        {
          title: '活动报名',
          desc: '选择入场券等级',
          date: '2023.9.11-10.9'
        },
        {
          title: '旅途起点',
          desc: '使用BLENDER创建你的OC角色',
          date: '2023.10.9-11.19'
        },
        {
          title: '冒险进阶',
          desc: '离开出生点，为OC角色赋予专属故事',
          date: '2023.11.20-12.31'
        },
        {
          title: '晋级评选',
          desc: '专业大咖导演及圈内大佬参与最终评选',
          date: '2024.1.1-1.14'
        },
        {
          title: '赢取宝藏',
          desc: '最终奖项颁发及公示',
          date: '2024.1.18'
        }
      ]
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {}
});
</script>

<style scoped lang="scss">
.timeline {
  background: url('@/assets/images/common/bg.webp') no-repeat center/cover;
  .timeline-wrapper {
    position: absolute;
    left: 55%;
    top: 50%;
    width: 60vw;
    height: 30vw;
    padding: 1.5vw;
    transform: translate(-50%, -50%);
    font-family: 'alph', sans-serif, serif;
    overflow: hidden;
    .title-wrapper {
      margin-bottom: 2.3vw;
      .title {
        font-size: 72px;
        font-weight: 700;
      }
      .title-en {
        color: rgba(255, 255, 255, 0.47);
        font-size: 14px;
        font-weight: 500;
      }
    }

    .timeline-list {
      margin-top: 158px;
      font-family: 'alph', sans-serif, serif;
      .line {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 5px;
        border-radius: 20px;
        background: rgba(255, 255, 255, 0.13);
        .timeline-item {
          position: relative;
          .content-wrapper {
            position: absolute;
            width: 10.4vw;
            padding: 15px 10px 15px 20px;
            border-radius: 20px;
            background: #535863;
            white-space: pre-wrap;
            &.up {
              bottom: 50px;
              transform: translateX(-50%);
            }
            &.down {
              top: 30px;
              transform: translateX(-50%);
            }
            .title {
              font-size: 20px;
              font-weight: 700;
            }
            .desc {
              color: rgba(255, 255, 255, 0.47);
              font-size: 14px;
              font-weight: 500;
            }
          }
          .date {
            position: absolute;
            font-size: 14px;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.47);
            white-space: nowrap;
            &.up {
              top: -30px;
              transform: translateX(-50%);
            }
            &.down {
              top: 30px;
              transform: translateX(-50%);
            }
          }
          .dot {
            width: 18px;
            height: 18px;
            background: #ffc300;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1700px) {
  .timeline .timeline-wrapper {
    top: 60%;
    height: 40vw;
  }
}

@media screen and (min-height: 800px) {
  .timeline .timeline-wrapper {
    top: 45%;
  }
}
</style>
